.table-container .table-responsive {
    max-height: 500px; 
    overflow: auto;
    border: 1px solid #ddd;
  }


.table-container-tall .table-responsive {
    max-height: 650px; 
    overflow: auto;
    border: 1px solid #ddd;
  }


.index-custom  {
    z-index:  1; 
  }

.table-fixed-custom thead th {
    position: sticky;
    top: 0;
    
    background-color: #fff; 
    border: 1px solid #ddd;
  }
  
.table {
    border-collapse: separate;
    border-spacing: 0;
  }
