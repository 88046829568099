.c-sidebar-nav-dropdown-items {
    overflow-y: auto !important;;   
}

@media (min-width: 992px) {
    .c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-items {
        max-height: 300px !important;;
    }
}

